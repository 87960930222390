<template>
	<div>

		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1 flex flex-wrap items-center">
				<h2 class="text-2xl font-bold mr-3">All Beneficiaries</h2>
				<router-link :to="{ name: 'beneficiaries-new' }" class="text-sm text-gray-700">+ Add New</router-link>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for beneficiary’s name, bank, account number etc."
						v-model="beneficiaries.query">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<datatable
				:data="beneficiaries.data"
				:columns="beneficiaries.columns"
				:query="beneficiaries.query"
				:loading="beneficiaries.loading"
				ref="table"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				beneficiaries: this.$options.resource([], {
					columns: [
						{
							name: 'name',
							th: 'Name',
						},
						{
							name: 'description',
							th: 'Description',
							render: (b, description) => this.$options.filters.truncate(description, 20)
						},
						{
							name: 'account_number',
							th: 'Account Number',
						},
						{
							name: 'bank_id',
							th: 'Bank',
						},
						{
							name: 'status',
							th: 'Status',
							render: (b, status) => {
								switch (status) {
									case 'approved':
										return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
									case 'declined':
										return `<div class="badge badge-red-soft-outline">
											Declined
										</div>`;
									case 'pending':
									default:
										return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
								}
							}
						},
					],
					selected: null,
					query: '',
				})
			}
		},
		computed: {
		},
		beforeMount() {
			this.getBeneficiaries();
		},
		methods: {
			async getBeneficiaries() {
				this.beneficiaries.loading = true;

				await this.sendRequest('corporate.beneficiaries.all', this.user.company_id, {
					success: response => {
						this.beneficiaries.data = response.data.beneficiaries;
					},
					error: error => {
						this.beneficiaries.error = error;
					}
				});

				this.beneficiaries.loading = false;
			}
		}
	}
</script>